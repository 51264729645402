import React from "react";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import TrackVisibility from "react-on-screen";
import SectionTitle from "../sectionTitle/SectionTitle";

const FeatureItems = [
  {
    title: "Mevcut Danışan",
    number: 50,
    image: "about-image-01.png",
    suffix: "+",
  },
  {
    title: "Hizmet Sayımız",
    number: 20,
    image: "about-image-02.png",
    suffix: "+",
  },
  {
    title: "Mezun Öğrenci",
    number: 300,
    image: "about-image-03.png",
    suffix: "+",
  },
  {
    title: "Memnun Kalan Danışan",
    number: 500,
    image: "about-image-04.png",
    suffix: "+",
  },
];

const AboutOne = ({ wrapperClass }) => {
  return (
    <div
      className={`eduvibe-home-two-counter bg-color-white ${
        wrapperClass || "edu-section-gap"
      }`}
    >
      <div className="container eduvibe-animated-shape">
        <div className="row align-items-center gy-5">
          <div className="col-lg-6">
            <div className="row g-5 pr--75">
              {FeatureItems.map((data, i) => (
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-6 col-md-6 col-sm-6 col-12"
                  animateOnce={true}
                  key={i}
                >
                  <div className="edu-counterup">
                    <div className="inner">
                      <div className="icon">
                        <img
                          src={`${process.env.PUBLIC_URL}./images/about/about-01/${data.image}`}
                          alt="Icon Thumb"
                        />
                      </div>
                      <div className="content">
                        <TrackVisibility once className="counter" tag="h3">
                          {({ isVisible }) =>
                            isVisible && isVisible ? (
                              <CountUp
                                className="eduvibe-about-counterup"
                                end={`${data.number}  `}
                                duration={1.5}
                                delay={0.1}
                                start={0}
                                suffix={`${data.suffix ? data.suffix : ""}`}
                              />
                            ) : (
                              ""
                            )
                          }
                        </TrackVisibility>
                        <span>{data.title}</span>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="choose-us-2">
              <div className="inner">
                <SectionTitle
                  classes="text-left"
                  slogan="NEDEN BİZ"
                  title="Mor Kampüs Akademi ve Danışmanlık"
                />
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="description mt--40 mb--30">
                    İlkelerimizden ödün vermeden size en uygun hizmeti
                    sunacağız.
                  </p>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-style-4"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="icon">
                      <img
                        src={`${process.env.PUBLIC_URL}./images/about/about-01/smile.png`}
                        alt="Icon Thumb"
                      />
                    </div>
                    <div className="content">
                      <h6 className="title">Doğruluk</h6>
                      <p>
                        Şartlar ne olursa olsun hem müşterilerimize hem
                        tedarikçilerimize her zaman doğruyu söyleyeceğiz.
                      </p>
                    </div>
                  </div>

                  <div className="edu-feature-list color-var-2">
                    <div className="icon">
                      <i className="icon-Support"></i>
                    </div>
                    <div className="content">
                      <h6 className="title">Şeffaflık</h6>
                      <p>
                        Bir şirketin sahip olabileceği en önemli varlığın insan
                        kaynağı olduğu gerçeğini hiç bir zaman unutmadan, her
                        tür kurumsal ve finansal veriyi çalışanlarımızla
                        paylaşacağız.
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-2">
            <img src="/images/shapes/shape-11.png" alt="Shape Thumb" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
