import React from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import AboutUsTwo from "./pages/innerpages/AboutUsTwo";
import ContactUs from "./pages/innerpages/ContactUs";
import CourseOne from "./pages/course/CourseOne";
import HomeTwo from "./pages/homepages/HomeTwo";
import Error from "./pages/innerpages/Error";

// Import Css Here
import "./assets/scss/style.scss";

function App() {
  return (
    <Router hashType={"noslash"}>
      <ScrollToTop>
        <Routes>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            element={<HomeTwo />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/hakkimizda"}`}
            element={<AboutUsTwo />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/bize-ulasin"}`}
            element={<ContactUs />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/hizmetlerimiz"}`}
            element={<CourseOne />}
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
