import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import HomeTwoService from "../../components/home-two/HomeTwoService";

const CourseOne = () => {
  return (
    <>
      <SEO title="Hizmetlerimiz - Mor Kampüs" />
      <Layout>
        <BreadcrumbOne
          title="Hizmetlerimiz"
          rootUrl="/"
          parentUrl="Anasayfa"
          currentUrl="Hizmetlerimiz"
        />
        <div className="edu-course-area edu-section-gap bg-color-white">
          <div className="container">
            <HomeTwoService initiallyVisible={true} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CourseOne;
