import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import AboutNine from "../../components/about/AboutNine";
import AboutOne from "../../components/about/AboutOne";
import CounterUpOne from "../../components/counterup/CounterUpOne";

const AboutUsTwo = () => {
  return (
    <>
      <SEO title="Hakkımızda - Mor Kampüs Akademi Danışmanlık" />
      <Layout>
        <BreadcrumbOne
          title="Hakkımızda"
          rootUrl="/"
          parentUrl="Anasayfa"
          currentUrl="Hakkımızda"
        />

        <AboutNine />

        <AboutOne />

        <CounterUpOne />
      </Layout>
    </>
  );
};

export default AboutUsTwo;
