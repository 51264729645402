import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import ContactUsForm from "../../components/contact/ContactUsForm";

const ContactUs = () => {
  return (
    <>
      <SEO title="Bize Ulaşın - Mor Kampüs" />
      <Layout>
        <BreadcrumbOne
          title="Bize Ulaşın - Mor Kampüs"
          rootUrl="/"
          parentUrl="Anasayfa"
          currentUrl="Bize Ulaşın"
        />
        <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Yardıma İhtiyacınız Mı Var?</span>
                  <h3 className="title">Bizimle İletişime Geçin</h3>
                </div>
              </div>
            </div>
            <div className="row g-5 mt--20">
              <div className="col-lg-6">
                <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                  <div className="row g-5">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 website">
                        <div className="inner">
                          <div className="icon">
                            <i className="ri-global-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">İnternet Sitemiz</h6>
                            <p>
                              <a href="https://morkampus.com/">
                                www.morkampus.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 phone">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-Headphone"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Bizi Arayın</h6>
                            <p>
                              <a href="tel: + 90 552 676 11 00">
                                + 90 552 676 11 00
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 email">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-mail-open-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Bize E-posta Gönderin</h6>
                            <p>
                              <a href="mailto:morkampusakademi@gmail.com">
                                morkampusakademi@gmail.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 location">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-map-pin-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Adresimiz</h6>
                            <p>
                              Mehmet Akif Ersoy Mah. Dosima Plaza, 325.Sk, No:3/
                              54 (6.kat), 06200-Yenimahalle/ANKARA
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>

        <div className="edu-contact-map-area edu-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map alignwide">
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.559974758831!2d32.76987439001891!3d39.97358970905332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34f9d422c23b1%3A0xb457568e9d519a68!2sMor%20Kelebek%20AJANS%20%22Marka%20Ol%22!5e0!3m2!1sen!2sus!4v1707909560511!5m2!1sen!2sus"
                    height="500"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ContactUs;
