import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/">Anasayfa</Link>
      </li>

      <li>
        <Link to="/hakkimizda">Hakkımızda</Link>
      </li>

      <li>
        <Link to="/hizmetlerimiz">Hizmetlerimiz</Link>
      </li>

      <li>
        <Link to="/bize-ulasin">Bize Ulaşın</Link>
      </li>
    </ul>
  );
};
export default Nav;
